import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    palette: {
      primary: {
        main: '#006699', // Azul más brillante
      },
      secondary: {
        main: '#ffcc00', // Amarillo cálido
      },
      background: {
        default: '#ffffff', // Blanco puro
      },
    },
    typography: {
      fontFamily: 'Roboto, Arial, sans-serif',
    },
  });
  

export default theme;
