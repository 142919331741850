import React, { useState } from "react";
import { Container, Typography, Box, TextField } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CarouselComponent from "../components/CarouselComponent";
import QuoteComponent from "../components/QuoteComponent";
import { useQuery } from "@tanstack/react-query";
import { useLanguageStore } from "../components/utils/LanguageStore";

const articleData1 = [
  {
    title: "Keylogging Attack",
    description:
      "Keylogging es conocida como Acoustic Keyboard Eavesdropping Keylogging Attack (Ataque de espionaje a través del teclado acústico).",
    link: "https://www.infobae.com/economia/2024/08/08/keylogging-como-funciona-la-nueva-amenaza-virtual-que-vacia-cuentas-bancarias-con-solo-tocar-una-tecla/",
    image:
      "https://www.redeszone.net/app/uploads-redeszone.net/2022/01/keylogger.jpg",
  },
  {
    title: "InfoSecMap",
    description:
      "Anuncios de Eventos y grupos de Ciberseguridad a nivel Internacional.",
    link: "https://infosecmap.com/",
    image:
      "https://infosecmap.com/wp-content/uploads/2020/11/InfoSecMap-round.png",
  },
];

const articleData2 = [
  {
    title: "ITAA: Asociación para adictos al Internet y Tecnología.",
    description: "Adictos a Internet y la Tecnología Anónimos.",
    link: "https://internetaddictsanonymous.org/es/espanol/",
    image:
      "https://internetaddictsanonymous.org/wp-content/uploads/2023/01/cropped-ITAA-logo-480px.png",
  },
];

const fetchArticles = async (): Promise<
  { title: string; description: string; link: string; image: string }[]
> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([...articleData1, ...articleData2]);
    }, 1000); // Simula 1 segundo de delay
  });
};

const ArticlesScreen: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");

  // Obtener función de traducción desde zustand
  const { t } = useLanguageStore();

  const { data: articles, isLoading, error } = useQuery({
    queryKey: ["articles"],
    queryFn: fetchArticles,
    staleTime: 1000,
    placeholderData: [],
  });

  const filteredArticles = articles?.filter(
    (article) =>
      article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) return <p>{t("articles.loading")}</p>;
  if (error) return <p>{t("articles.error")}</p>;

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: {
              xs: "1.5rem",
              sm: "2rem",
              md: "2.5rem",
              lg: "3rem",
              xl: "3.5rem",
            },
            textAlign: "center",
          }}
        >
          {t("articles.title")}
        </Typography>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            padding: "15px",
          }}
        >
          <TextField
            label={t("articles.searchPlaceholder")}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: "70%" }}
          />
        </Box>
        <Box sx={{ width: "100%", padding: "16px 0" }}>
          <CarouselComponent cardData={filteredArticles ?? []} />
        </Box>

        {filteredArticles && filteredArticles.length === 0 && (
          <Typography variant="h6" color="textSecondary" sx={{ marginTop: 2 }}>
            {t("articles.noResults")}
          </Typography>
        )}

        <QuoteComponent
          quote={t("articles.privacyQuote")}
          author={t("articles.privacyAuthor")}
        />
      </Container>
      <Box sx={{ height: "300px", backgroundColor: "#f0f0f0" }} />
      <Footer />
    </div>
  );
};

export default ArticlesScreen;
