import React, { useState } from "react";
import { Container, Typography, Box, TextField, Modal } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CarouselComponent from "../components/CarouselComponent";
import QuoteComponent from "../components/QuoteComponent";
import { useQuery } from "@tanstack/react-query";
import { useLanguageStore } from "../components/utils/LanguageStore";
import CustomButton from "../components/CustomButton";
import PomodoroTimer from "../components/PomodoroTimer";

const educationData1 = [
  {
    title: "Test Anti Phishing",
    description: "Jigsaw Game realizado por Google. | **Gratis**",
    link: "https://phishingquiz.withgoogle.com/",
    image:
      "https://imgs.search.brave.com/twfrx2MmjON9xdpAidfTMVgWaG663Or8B5E81zG-iGM/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pLmlu/c2lkZXIuY29tLzVj/NDc2YTZiYmRlNzBm/NGQ2MDIwNTBjMD93/aWR0aD03MDA",
  },
  {
    title: "Test contra CYBERBULLYING",
    description: "Quiz interacitvo realizado por Wordwall.net | *Gratis*",
    link: "https://wordwall.net/es/resource/3927645/ciberbullying",
    image:
      "https://wordwallscreens.azureedge.net/400/5173e56ecd7a4291adee6ff92b77b390_0",
  },
];

const educationData2 = [
  {
    title: "FreeCodeCamp",
    description:
      "Explora cursos y programas para adquirir habilidades en diversos roles dentro del campo de la Seguridad de la Información. *Gratis*.",
    link: "https://www.freecodecamp.org/espanol/learn/information-security/",
    image:
      "https://storage.googleapis.com/grow-with-goog-publish-prod-media/images/New_GWG_site__800_x_421_px_1.original.format-jpeg.jpg",
  },
  {
    title: "Platzi",
    description:
      "Escuela de Ciberseguridad dictada por la Plataforma Educativa Online Platzi. | *Pago*",
    link: "https://platzi.com/escuela/ciberseguridad/",
    image:
      "https://latamlist.com/wp-content/uploads/2018/05/platzi-logo-huge.png",
  },
];

const fetchEducationalResources = async (): Promise<
  { title: string; description: string; link: string; image: string }[]
> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([...educationData1, ...educationData2]);
    }, 1000);
  });
};

const EducationScreen: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // Obtener función de traducción desde zustand
  const { t } = useLanguageStore();

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const { data: eduResource, isLoading, error } = useQuery({
    queryKey: ["educationalResources"],
    queryFn: fetchEducationalResources,
    staleTime: 1000,
    placeholderData: [],
  });

  const filteredEducationalResources = eduResource?.filter(
    (eduResource) =>
      eduResource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      eduResource.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) return <p>{t("education.loading")}</p>;
  if (error) return <p>{t("education.error")}</p>;

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: {
              xs: "1.5rem",
              sm: "2rem",
              md: "2.5rem",
              lg: "3rem",
              xl: "3.5rem",
            },
            textAlign: "center",
          }}
        >
          {t("education.title")}
        </Typography>

        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            padding: "15px",
          }}
        >
          <TextField
            label={t("education.searchPlaceholder")}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: "70%" }}
          />
        </Box>

        <Box sx={{ width: "100%", padding: "16px 0" }}>
          <CarouselComponent cardData={filteredEducationalResources ?? []} />
        </Box>

        {filteredEducationalResources && filteredEducationalResources.length === 0 && (
          <Typography
            variant="h6"
            color="textSecondary"
            sx={{ marginTop: 2 }}
          >
            {t("education.noResults")}
          </Typography>
        )}

        <QuoteComponent
          quote={t("education.digitalBreakMessage")}
          author={t("education.author")}
        />

        <Box sx={{ height: "100px", backgroundColor: "#f0f0f0" }} />

        <CustomButton
          label={t("education.digitalBreakCTA")}
          onClick={handleOpenModal}
          sx={{ mt: 4, mb: { xs: 8, sm: 4 } }}
        />

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              px: 2,
            }}
          >
            <PomodoroTimer onClose={handleCloseModal} />
          </Box>
        </Modal>
      </Container>
      <Box sx={{ height: "300px", backgroundColor: "#f0f0f0" }} />
      <Footer />
    </div>
  );
};

export default EducationScreen;
