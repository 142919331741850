import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

interface CustomButtonProps {
  label: string;
  to?: string;
  onClick?: () => void;
  className?: string; // Clases adicionales para el label
  sx?: object;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  to,
  onClick,
  className,
  sx,
}) => {
  const buttonStyles = {
    borderRadius: "30px",
    padding: "10px 20px",
    backgroundColor: "#10A5D1", // Color de fondo
    color: "white",
    transition: "all 0.3s ease", // Transiciones suaves
    boxShadow: "0px 4px 20px rgba(16, 165, 209, 0.4)", // Sombra sutil
    "&:hover": {
      backgroundColor: "#0E7A9D", // Color en hover
      boxShadow: "0px 6px 25px rgba(16, 165, 209, 0.6)",
    },
    ...sx, // Combina estilos personalizados
  };

  return (
    <Button
      component={to ? Link : "button"}
      to={to}
      onClick={onClick}
      variant="contained"
      sx={buttonStyles}
      className={className}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
