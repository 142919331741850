import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { FormValues, validationSchema } from "./schemas/schema";

// Estilo del contenedor del formulario
const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "8px",
  padding: "30px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  maxWidth: "400px",
  margin: "0 auto",
}));

const ContactForm: React.FC = () => {
  // Inicializa React Hook Form con Zod Resolver
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
  });

  // Maneja el envío del formulario
  const onSubmit = async (values: FormValues) => {
    try {
      const response = await fetch("https://formspree.io/f/xbljkvrp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error("Hubo un problema al enviar tu mensaje.");
      }

      alert("¡Gracias por tu mensaje! Nos pondremos en contacto pronto.");
      reset(); // Limpia los campos del formulario después del envío
    } catch (error) {
      console.error(error);
      alert("No pudimos enviar tu mensaje. Por favor, inténtalo más tarde.");
    }
  };

  return (
    <FormContainer>
      <Typography
        variant="h5"
        component="h2"
        textAlign="center"
        color="#10A5D1"
        mb={2}
        fontWeight="bold"
      >
        Únete al Cambio
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color="textSecondary"
        mb={3}
      >
        Colaboremos juntos por una comunidad digital más segura y consciente.
      </Typography>
      {/* Formulario con React Hook Form */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={3}>
          <TextField
            label="Correo Electrónico"
            fullWidth
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Box>

        <Box mb={3}>
          <TextField
            label="Tu Mensaje"
            fullWidth
            multiline
            rows={4}
            {...register("message")}
            error={!!errors.message}
            helperText={errors.message?.message}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#10A5D1",
            color: "#fff",
            "&:hover": { backgroundColor: "#0d82a7" },
            textTransform: "none",
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Enviando..." : "Enviar"}
        </Button>
      </form>
    </FormContainer>
  );
};

export default ContactForm;
