import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";

interface QuoteComponentProps {
  quote: string;
  author: string;
}

const QuoteComponent: React.FC<QuoteComponentProps> = ({ quote, author }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <Box
        sx={{
          margin: "30px 0",
          padding: "20px",
          borderRadius: "12px",
          backgroundColor: "#f9fafb",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          textAlign: "center",
          maxWidth: "600px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Typography
          variant="h6"
          component="blockquote"
          gutterBottom
          sx={{
            fontStyle: "italic",
            color: "#333",
            lineHeight: 1.6,
          }}
        >
          "{quote}"
        </Typography>
        <Typography
          variant="subtitle1"
          component="cite"
          color="textSecondary"
          sx={{ fontWeight: 500, marginTop: "10px", display: "block" }}
        >
          - {author}
        </Typography>
      </Box>
    </motion.div>
  );
};

export default QuoteComponent;
