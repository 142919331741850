import { create } from "zustand";
import translations from "./Translations";

interface LanguageState {
  language: "es" | "en"; // Idiomas disponibles
  setLanguage: (lang: "es" | "en") => void; // Cambiar idioma
  t: (key: string) => string; // Función para traducir
}

export const useLanguageStore = create<LanguageState>((set, get) => ({
  language: "es", // Idioma por defecto
  setLanguage: (lang) => set({ language: lang }),
  t: (key) => {
    const lang = get().language;
    const keys = key.split(".");
    let result: any = translations[lang];

    for (const k of keys) {
      if (!result[k]) {
        console.warn(`Missing translation for key: "${key}"`);
        return key; // Si la traducción no existe, devuelve la clave original
      }
      result = result[k];
    }

    return result || key; // Retorna la traducción o la clave
  },
}));
