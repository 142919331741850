import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link as ScrollLink } from "react-scroll"; 
import { Link } from "react-router-dom";
import logo from "../assets/ConcienciaDigitalLogo1.png";
import LanguageSwitcher from "./LanguageSwitcher";
import { useLanguageStore } from "../components/utils/LanguageStore";

function Header() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { t } = useLanguageStore();

  const pages: { name: string; path: string }[] = [
    { name: t("header.home"), path: "/" },
    { name: t("header.about"), path: "nosotros" },
    { name: t("header.education"), path: "/educacion" },
    { name: t("header.articles"), path: "/articulos" },
    { name: t("header.community"), path: "/comunidad" },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#1871b1", boxShadow: "none" }}
      >
        <Toolbar sx={{ px: 0, minHeight: 64 }}>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            <Link to="/">
              <img
                src={logo}
                alt="Conciencia Digital Logo"
                style={{ height: 80, width: "auto" }}
              />
            </Link>
          </Box>

          {/* Menú de navegación para pantallas grandes */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            {pages.map((page) =>
              page.name === t("header.about") ? (
                <ScrollLink
                  key={page.name}
                  to="nosotros"
                  smooth={true}
                  duration={500}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      transition: "color 0.3s ease",
                      "&:hover": {
                        color: "#d1e1f0",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                </ScrollLink>
              ) : (
                <Link
                  key={page.name}
                  to={page.path}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      transition: "color 0.3s ease",
                      "&:hover": {
                        color: "#d1e1f0",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {page.name}
                  </Button>
                </Link>
              )
            )}
          </Box>

          {/* Icono del menú para pantallas pequeñas */}
          <Box
            sx={{ display: { xs: "flex", md: "none" }, alignItems: "center" }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link to={page.path} style={{ textDecoration: "none" }}>
                    <Button
                      sx={{
                        color: "#1871b1",
                        transition: "color 0.3s ease",
                        "&:hover": {
                          color: "#d1e1f0",
                          textDecoration: "underline",
                        },
                      }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Iconos de redes sociales */}
          <Box sx={{ flexGrow: 0, display: "flex", alignItems: "center" }}>
            <LanguageSwitcher />

            <Tooltip title={t("header.socialMediaTooltip")}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <a
                  href="https://www.linkedin.com/company/concienciadigital/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      p: 0,
                      color: "#f0f0f0",
                      transition: "color 0.3s ease",
                      "&:hover": {
                        color: "#10A5D1",
                      },
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </a>
                <a
                  href="https://www.youtube.com/watch?v=GSn2oU3lHhk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    sx={{
                      p: 0,
                      color: "#f0f0f0",
                      transition: "color 0.3s ease",
                      "&:hover": {
                        color: "#10A5D1",
                      },
                    }}
                  >
                    <PodcastsIcon />
                  </IconButton>
                </a>
              </Box>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      {/* Ajuste del espacio extra */}
      <Toolbar
        id="back-to-top-anchor"
        sx={{ minHeight: 0, bgcolor: "transparent" }}
      />
    </React.Fragment>
  );
}

export default Header;
