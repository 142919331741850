const translations = {
  en: {
    home: {
      welcomeMessage:
        "Platform of resources aimed at promoting and elevating digital awareness.",
      aboutUsTitle: "About Us",
      aboutUsMessage:
        "Welcome to Conciencia Digital, where we promote safe technology use. Our mission is to educate and protect people from cybercrime risks like cyberbullying and grooming.",
      mission:
        "Our mission is to empower individuals and communities with tools and knowledge to navigate the digital world safely and consciously.",
      initiatives: "Join us and follow our initiatives on social media:",
      hashtags:
        "#AntiCyberBullying #FightAgainstGrooming #AntiDigitalScams #EthicalHackers",
      supportButton: "Support Us",
    },
    education: {
      title: "Educational Resources",
      searchPlaceholder: "Search educational resources",
      loading: "Loading resources...",
      error: "Error loading educational resources",
      noResults: "No resources found.",
      digitalBreakMessage:
        "Take a breath and improve your focus with our Digital Break Timer.",
      digitalBreakCTA: "I want a break",
    },
    articles: {
      title: "Informative Articles",
      searchPlaceholder: "Search articles",
      loading: "Loading articles...",
      error: "Error loading articles",
      noResults: "No articles found.",
      privacyQuote:
        "Privacy is a right, not a privilege. It’s not something to justify, but something to defend.",
      privacyAuthor: "Edward Snowden",
    },
    community: {
      title: "Every Contribution Counts",
      description:
        "Your support is key to promoting digital awareness! With every contribution, you help keep this website accessible and strengthen security in the digital environment.",
      callToAction: "Join our effort for a safer digital world.",
      joinButton: "Join",
    },
    header: {
      home: "Home",
      about: "About Us",
      education: "Education",
      articles: "Articles",
      community: "Community",
      socialMediaTooltip: "Social Media & Podcast Links",
    },
  },
  es: {
    home: {
      welcomeMessage:
        "Plataforma de recursos que busca promover y elevar la conciencia del consumo digital.",
      aboutUsTitle: "Sobre Nosotros",
      aboutUsMessage:
        "Bienvenido a Conciencia Digital, donde promovemos un uso seguro de la tecnología. Nuestra misión es educar y proteger a las personas de los riesgos del cibercrimen como el ciberbullying y el grooming.",
      mission:
        "Nuestra misión es empoderar a individuos y comunidades con herramientas y conocimientos para navegar el mundo digital de manera segura y consciente.",
      initiatives:
        "¡Únete a nosotros y sigue nuestras iniciativas en redes sociales!",
      hashtags:
        "#AntiCiberBullying #LuchaContraElGrooming #AntiEstafasDigitales #HackersEticos",
      supportButton: "Apoyar",
    },
    education: {
      title: "Recursos Educativos",
      searchPlaceholder: "Buscar recursos educativos",
      loading: "Cargando recursos...",
      error: "Error al cargar los recursos educativos",
      noResults: "No se encontraron recursos.",
      digitalBreakMessage:
        "Toma un respiro y mejora tu enfoque con nuestro Digital Break Timer.",
      digitalBreakCTA: "Quiero un Break",
    },
    articles: {
      title: "Artículos Informativos",
      searchPlaceholder: "Buscar artículos",
      loading: "Cargando artículos...",
      error: "Error al cargar los artículos",
      noResults: "No se encontraron artículos.",
      privacyQuote:
        "La privacidad es un derecho, no un privilegio. No es algo que deba ser justificado, sino algo que debe ser defendido.",
      privacyAuthor: "Edward Snowden",
    },
    community: {
      title: "Cada Aporte Cuenta",
      description:
        "¡Tu apoyo es clave para promover la conciencia digital! Con cada contribución, ayudas a mantener este sitio web accesible y a fortalecer la seguridad en el entorno digital.",
      callToAction: "Únete a nuestro esfuerzo por un mundo digital más seguro.",
      joinButton: "Únete",
    },
    header: {
      home: "Inicio",
      about: "Nosotros",
      education: "Educación",
      articles: "Artículos",
      community: "Comunidad",
      socialMediaTooltip: "Enlaces de Redes Sociales y Podcast",
    },
  },
};

export default translations;
