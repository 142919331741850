import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import ContactForm from "../components/ContactForm";
import { useLanguageStore } from "../components/utils/LanguageStore";

const CommunityScreen: React.FC = () => {
  const { t } = useLanguageStore(); // Hook para obtener las traducciones dinámicas

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Box textAlign="center" py={5}>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              color: "#10A5D1",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              mb: 3,
            }}
          >
            {t("community.title")}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ mt: 2, mb: 4 }}>
            {t("community.description")}
            <br />
            <strong>{t("community.callToAction")}</strong>
          </Typography>

          <CustomButton
            label={t("community.joinButton")}
            to="https://buymeacoffee.com/concienciadigital"
            onClick={() => console.log("Button clicked!")}
          />
        </Box>

        <Box mt={8} mb={8}>
          <ContactForm />
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default CommunityScreen;
