import React from "react";
import { CardContent, Typography, Button, SxProps, Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

interface MediaCardProps {
  title: string;
  description: string;
  link: string;
  image: string;
  sx?: SxProps<Theme>;
}

const StyledCard = styled(motion.div)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "250px",
  height: "400px",
  borderRadius: "16px",
  overflow: "hidden",
  backgroundColor: "#fff",
  boxShadow: theme.shadows[1],
  transition: "all 0.3s ease",
}));

const StyledCardMedia = styled("img")({
  height: "140px",
  width: "100%",
  objectFit: "cover",
});

const MediaCard: React.FC<MediaCardProps> = ({
  title,
  description,
  link,
  image,
  sx,
}) => {
  const maxLength = 100; // Longitud máxima para la descripción
  const truncatedDescription =
    description.length > maxLength
      ? description.substring(0, maxLength) + "..."
      : description;

  return (
    <StyledCard
      sx={sx}
      whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)" }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <StyledCardMedia src={image} alt={title} />
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {truncatedDescription}
        </Typography>
      </CardContent>
      <Button
        variant="contained"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ margin: 1, marginBottom: 2, backgroundColor: "#1871b1" }}
      >
        Ver más
      </Button>
    </StyledCard>
  );
};

export default MediaCard;
