import React, { useState } from "react";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MediaCard from "./MediaCard";

interface CarrouselComponentProps {
  cardData: {
    title: string;
    description: string;
    link: string;
    image: string;
  }[];
}

const CarrouselComponent: React.FC<CarrouselComponentProps> = ({
  cardData,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cardsPerView = isMobile ? 1 : 3;
  const totalCards = cardData.length;

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + cardsPerView < totalCards
        ? prevIndex + 1
        : prevIndex // Detén el movimiento al llegar al final
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0
        ? prevIndex - 1
        : prevIndex // Detén el movimiento si ya está en el principio
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      {/* Botón Anterior */}
      <IconButton
        onClick={handlePrev}
        sx={{
          position: "absolute",
          left: "16px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <NavigateBeforeIcon />
      </IconButton>

      {/* Contenedor del Carrusel */}
      <Box
        sx={{
          display: "flex",
          transition: "transform 0.5s ease-in-out",
          transform: `translateX(-${(currentIndex * 100) / cardsPerView}%)`,
          gap: "16px",
        }}
      >
        {cardData.map((card, index) => (
          <Box
            key={index}
            sx={{
              flex: `0 0 ${100 / cardsPerView}%`,
              padding: "0 8px",
            }}
          >
            <MediaCard {...card} />
          </Box>
        ))}
      </Box>

      {/* Botón Siguiente */}
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          right: "16px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
        }}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default CarrouselComponent;
