import React, { useState } from "react";
import { useLanguageStore } from "./utils/LanguageStore";
import { Menu, MenuItem, IconButton, Typography } from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";

const LanguageSwitcher: React.FC = () => {
  const { language, setLanguage } = useLanguageStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang: "es" | "en") => {
    setLanguage(lang);
    handleClose();
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Typography variant="body1" sx={{ marginRight: 1, color: "white" }}>
        {language === "es" ? "ES" : "EN"}
      </Typography>
      <IconButton
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: "white" }}
      >
        <TranslateIcon />
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => handleLanguageChange("es")}>
  <span style={{ fontFamily: "Segoe UI Emoji" }}>🇪🇸</span> Español
</MenuItem>
<MenuItem onClick={() => handleLanguageChange("en")}>
  <span style={{ fontFamily: "Segoe UI Emoji" }}>🇬🇧</span> English
</MenuItem>

      </Menu>
    </div>
  );
};

export default LanguageSwitcher;
