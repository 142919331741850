import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline"; // Para aplicar estilos globales y normalización
import HomeScreen from "./screens/HomeScreen";
import EducacionScreen from "./screens/EducationScreen";
import ArticulosScreen from "./screens/ArticlesScreen";
import CommunityScreen from "./screens/CommunityScreen";
import PomodoroTimer from "./components/PomodoroTimer";

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/nosotros" element={<HomeScreen />} />
          <Route path="/educacion" element={<EducacionScreen />} />
          <Route path="/articulos" element={<ArticulosScreen />} />
          <Route path="/comunidad" element={<CommunityScreen />} />
          <Route
            path="/pomodoro"
            element={
              <PomodoroTimer
                onClose={function (): void {
                  throw new Error("Function not implemented.");
                }}
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
