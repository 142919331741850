import { z } from "zod";

export const validationSchema = z.object({
  email: z
    .string()
    .email("Por favor, ingresa un correo válido.")
    .min(1, "El correo electrónico es requerido."),
  message: z
    .string()
    .min(10, "El mensaje debe tener al menos 10 caracteres.")
    .min(1, "El mensaje es requerido."),
});

export type FormValues = z.infer<typeof validationSchema>;
