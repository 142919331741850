import React from "react";
import { Container, Typography, Box } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import logo from "../assets/ConcienciaDigitalLogo1.png"
import QuestionsAccordeon from "../components/QuestionsAccordeon";
import { useLanguageStore } from "../components/utils/LanguageStore";

const HomeScreen: React.FC = () => {
  const { t } = useLanguageStore();

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Box textAlign="center" py={5}>
          <img
            src={logo}
            alt="Conciencia Digital Logo"
            style={{
              height: "250px",
              width: "auto",
              marginBottom: "15px",
              objectFit: "contain",
            }}
          />

          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            {t("home.welcomeMessage")}
          </Typography>
          <CustomButton
            label={t("home.supportButton")}
            to="/articulos"
            onClick={() => console.log("Button clicked!")}
          />
        </Box>

        <QuestionsAccordeon />

        <Box sx={{ height: "200px", backgroundColor: "#f0f0f0" }} />

        <Box
          sx={{
            paddingBottom: 5,
            marginTop: -10,
            textAlign: "center",
            backgroundColor: "#f0f0f0",
          }}
        >
          <Typography
            id="nosotros"
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              color: "#10A5D1",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            {t("home.aboutUsTitle")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("home.aboutUsMessage")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("home.mission")}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t("home.initiatives")}
            <br />
            <strong>{t("home.hashtags")}</strong>
          </Typography>

          <CustomButton
            label={t("home.supportButton")}
            to="/comunidad"
            onClick={() => console.log("Button clicked!")}
            sx={{ mt: 4 }}
          />
        </Box>

        <Box sx={{ height: "300px", backgroundColor: "#f0f0f0" }} />
      </Container>

      <Footer />
    </div>
  );
};

export default HomeScreen;
