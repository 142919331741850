import React from 'react';
import ReactDOM from 'react-dom/client'; // Importar desde 'react-dom/client'
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import './index.css'; // CSS global si es necesario
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';


const queryClient = new QueryClient();


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />

    </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);


reportWebVitals();